@font-face {
	font-family: 'AvenirLTStd-Light';
	src: url('fonts/32D92D_1_0.eot');
	src: url('fonts/32D92D_1_0.eot?#iefix') format('embedded-opentype'), url('fonts/32D92D_1_0.woff2') format('woff2'),
		url('fonts/32D92D_1_0.woff') format('woff'), url('fonts/32D92D_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'AvenirLTStd-Medium';
	src: url('fonts/32D92D_2_0.eot');
	src: url('fonts/32D92D_2_0.eot?#iefix') format('embedded-opentype'), url('fonts/32D92D_2_0.woff2') format('woff2'),
		url('fonts/32D92D_2_0.woff') format('woff'), url('fonts/32D92D_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'AvenirLTStd-Bold';
	src: url('fonts/32D92D_0_0.eot');
	src: url('fonts/32D92D_0_0.eot?#iefix') format('embedded-opentype'), url('fonts/32D92D_0_0.woff2') format('woff2'),
		url('fonts/32D92D_0_0.woff') format('woff'), url('fonts/32D92D_0_0.ttf') format('truetype');
}

$white: #ffffff;
$black: #000000;
$blue: #4990e2;
$light-blue: #81acde;
$grey: #646464;
$grey-light: #b8bcbf;
$red-error: #ff5e5e;
$green-ok: #27bb7d;

body {
	font-size: 16px;
	font-family: AvenirLTStd-Light;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-o-font-smoothing: antialiased;
	font-smoothing: antialiased;
	background: url("img/background-reg.png") no-repeat #0e1011 center bottom fixed;
	background-size: cover;
	font-weight: 100;
	margin: 49px 20px 20px;
}

h2 {
	font-family: 'AvenirLTStd-Bold';
	color: white;
	font-size: 16px;
	font-weight: normal;
}

h3 {
	color: white;
	font-size: 15px;
	font-weight: normal;
	text-align: left;
}

hr {
	background-color: white;
	border: 0;
	display: inline-block;
	height: 1px;
	margin: 19px 0 0;
	opacity: 0.3;
	width: 100%;
}

.logo {
	padding: 38px 0;
	display: block;
	margin: 0 auto;
}

.btn {
	appearance: none;
	background: $blue;
	border-radius: 100px;
	border: none;
	color: $white;
	cursor: pointer;
	font-family: AvenirLTStd-Bold;
	font-size: 19px;
	height: 44px;
	margin-bottom: 20px;
	margin-top: 30px;
	outline: none;
	padding: 7px 40px;
	width: 50%;
	-webkit-appearance: none;
	&:hover {
		background: $light-blue;
		transition: background 0.3s ease;
	}
	&:disabled {
		background: none;
		border: 2px solid $grey-light;
		cursor: auto;
		color: $grey-light;
		font-weight: bold;
	}
}

.btn-change-pw {
	width: 62%;
	min-width: 12rem;
}

.btn-loading {
	border-radius: 20px;
	cursor: pointer;
	outline: none;
	width: 50%;
	color: $white;
	background: $light-blue;
	padding: 7px 40px;
	height: 40px;
	border: none;
	font-family: AvenirLTStd-Light;
	margin-top: 10px;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
}

.margin-bottom {
	margin-bottom: 30px;
}

.margin-bottom-50 {
	margin-bottom: 50px;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.text-center {
	text-align: center;
}

.form-center {
	margin: 0 auto;
}

.topPanel {
	padding-bottom: 10px;
}

.bg-form {
	background-color: rgba(58, 61, 69, 0.8);
	border-radius: 10px;
	margin: 0 auto;
	max-width: 442px;
}

form {
	margin: 10px;
	input:focus {
		outline: none;
	}

	input {
		border: 0;
		border-radius: 0;
		color: white;
		padding: 11px 3% 7px 33px;
		font-size: 16px;
		margin-bottom: 25px;
		width: 86%;
		font-family: AvenirLTStd-Light;
		&::placeholder {
			color: #aeb1ba;
		}
		&:focus:invalid {
			@extend .pw-error;
		}
		&:invalid {
			box-shadow: none;
		}
		& + span.icon-error {
			display: none;
		}
		&:focus:invalid + span.icon-error {
			display: inline;
			box-shadow: none;
		}
		&.half {
			width: 35%;
		}
		&.last {
			margin-bottom: 6px;
		}
	}
}

.bg-icon-phone {
	background: url(img/icon-phone-signup-1x.png) no-repeat rgba(35, 38, 45, 0.6) scroll 9px;
}

.bg-icon-user {
	border-left: 4px solid $blue;
	background: url(img/icon-name-signup-1x.png) no-repeat rgba(35, 38, 45, 0.6) scroll 9px;
}

.bg-icon-user-login {
	background: url(img/icon-name-signup-1x.png) no-repeat rgba(35, 38, 45, 0.6) scroll 9px;
}

.bg-icon-email {
	border-left: 4px solid $blue;
	background: url(img/icon-mail-signup-1x.png) no-repeat rgba(35, 38, 45, 0.6) scroll 9px;
}

.bg-icon-pw {
	background: url(img/icon-lock-signup-1x.png) no-repeat rgba(35, 38, 45, 0.6) scroll 9px;
}

.register {
	.logo {
		padding: 30px 0;
	}
}

.wrapper-pw {
	display: inline-block;
	position: relative;
	width: 100%;
	&.half {
		width: 48%;
		& input {
			width: 73%;
		}
	}
}

.pw-ok {
	border: 1px solid $green-ok;
}

.pw-error {
	border-left: 4px solid $blue;
}

.pw-error-msg,
.pw-weak-msg {
	color: $red-error;
	font-size: 14px;
	position: absolute;
	top: 14px;
	right: 15px;
}

.pw-ok-msg,
.pw-good-msg {
	color: $green-ok;
	font-size: 14px;
	position: absolute;
	top: 14px;
	right: 15px;
}

.email-error-msg {
	color: $red-error;
	font-size: 12px;
	position: absolute;
	top: 52px;
	left: 0;
}

.code-error-msg {
	color: $red-error;
	display: block;
	font-size: 12px;
	margin-bottom: 10px;
}

.icon {
	height: 16px;
	width: 16px;
	position: absolute;
	right: 8px;
	top: 11px;
	&-ok {
		@extend .icon;
		background: url(img/icon-check.png) no-repeat scroll right;
	}
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.footer-text {
	color: $white;
}

a,
.text-link {
	color: $blue;
	cursor: pointer;
	font-family: 'AvenirLTStd-Bold';
	text-decoration: none;
	&:hover {
		color: $light-blue;
		transition: all 0.3s ease;
	}
}

// Importants override the inline css added by salesforces.
form .message {
	font-family: 'AvenirLTStd-Medium';
	margin-bottom: 25px;
	.messageTable {
		color: $red-error;
		margin: 0 auto !important;
	}
	ul {
		text-align: left;
	}
}

.messageText span h4 {
	color: #ff5a5a !important;
	display: none;
}

.confirm-reg {
	padding: 0 65px 15px 65px !important;
	margin-bottom: 25px !important;
	p {
		margin-top: 0;
	}
}

.confirm-reg-msg {
	color: $white;
}

.bottom-note {
	color: $white;
	float: left;
	font-size: 0.75rem;
	letter-spacing: 0.025em;
}

table.confirm-reg-table {
	width: 100%;
	text-align: center;
}

.table-msg {
	width: 100%;
}

.link {
	border-radius: 20px;
	cursor: pointer;
	outline: none;
	color: $white;
	background: $blue;
	padding: 14px 40px;
	border: none;
	font-family: AvenirLTStd-Light;
	margin: 20px auto 0;
	display: block;
	width: 135px;
	text-decoration: none;
	&:hover {
		background: $light-blue;
		transition: all 0.3s ease;
		color: $white;
	}
}

.white-text {
	color: #fff;
}

.white-box {
	color: #fff;
	padding: 15px;
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.2);
	width: 50%;
}

#change-password {
	.messageText h4 {
		margin: 10px 0 0 0;
	}
	h2 {
		margin-bottom: 30px;
		margin-top: 0;
	}
	.new-user {
		margin-bottom: 37px;
	}
}

.pw-forgotten {
	display: inline-block;
	font-size: 14px;
	text-align: right;
	width: 100%;
}

.pw-requirements {
	background: rgba(50, 49, 57, 0.6);
	padding: 3px;
	color: white;
	margin-bottom: 30px;
	padding-bottom: 11px;
	p {
		margin: 12px 0 8px;
	}
	.no-margin {
		margin: 0;
	}
	.requirements-wrapper {
		margin: 0 auto;
		width: 70%;
		p {
			text-align: left;
		}
	}
}

.new-user {
	color: white;
	display: inline-block;
	margin-top: 30px;
}

.invalid {
	background: url("img/unselected.png");
	height: 16px;
	width: 16px;
	display: inline-block;
	background-size: 100%;
	margin-right: 9px;
}

.valid {
	background: url("img/selected.png");
	height: 16px;
	width: 16px;
	display: inline-block;
	background-size: 100%;
	margin-right: 9px;
}

@media only screen and (max-device-width: 1024px) {
	.footer-text,
	.link-footer {
		display: none;
	}
	.bg-form {
		width: auto;
	}
	form {
		width: 80%;
	}
	.hide-on-device {
		display: none;
	}
}

@media only screen and (max-device-width: 767px) {
	body {
		margin: 20px 18px;
	}

	.no-background {
		background: none;
	}

	.bg-form {
		width: auto;
	}

	.logo {
		padding: 26px 0;
	}

	.new-user {
		bottom: 0;
		left: 0;
		margin-bottom: 11px;
		width: 100%;
	}

	.wrapper-pw {
		text-align: left;
		.half {
			width: 100%;
		}
	}

	form {
		width: 100%;
	}

	form input,
	.wrapper-pw.half input {
		width: 88%;
		&.float-right,
		&.float-left {
			float: none;
		}
	}

	.btn {
		width: 50%;
	}

	.wrapper-pw.half {
		&.float-right,
		&.float-left {
			float: none;
		}
	}

	.icon {
		right: 12px;
		top: 14px;
	}

	.email-error-msg {
		top: 43px;
	}

	.hide-on-device {
		display: none;
	}

	.register {
		margin-bottom: 60px;
	}

	.text-center .bottom-note {
		text-align: left;
	}
}

@media only screen and (min-device-width: 768px) {
	// BROWSER
	form {
		width: 80%;
	}

	body {
		background: url("img/bg-browser.png") no-repeat $black center center fixed;
		background-size: cover;
	}

	form input {
		padding: 11px 12px 7px 33px;
		width: 87%;
	}

	.email-error-msg {
		top: 43px;
	}
}
